<template>
  <div class="snack-banner-container" :class="{ 'snack-banner-container--empty': !snackBanners.length }">
    <div class="snack-banner__wrapper">
      <TransitionGroup name="reverseSlideY">
        <div
          v-for="snackBanner in snackBanners"
          id="snack-banner"
          :key="snackBanner.id"
          class="snack-banner"
          :class="[`snack-banner--${snackBanner.type}`]"
        >
          <div class="snack-banner__icon">
            <UIIcon :name="iconMap[snackBanner.type]" size="lg" />
          </div>
          <div class="snack-banner__text">
            {{ snackBanner.text }}
          </div>
          <div class="snack-banner__close" @click="resetSnackBanner(snackBanner.id)">
            <UIIcon name="big-close" class="cursor-pointer" />
          </div>
        </div>
      </TransitionGroup>
    </div>
  </div>
</template>

<script setup lang="ts">
import useUIStore from '~/store/ui'

const iconMap = {
  success: 'check-filled',
  info: 'info-circle-filled',
  error: 'alert-triangle-filled',
}

const UIStore = useUIStore()

const { resetSnackBanner } = UIStore

const { snackBanners } = storeToRefs(UIStore)

const resetTimers = ref<number[]>([])

watch(
  () => snackBanners.value,
  value => {
    if (value) {
      const ids = value.map(snack => snack.id)

      ids.forEach((id, index) => {
        if (!resetTimers.value.includes(id)) {
          resetTimers.value.push(id)

          setTimeout(
            () => {
              resetSnackBanner(id)

              resetTimers.value = resetTimers.value.filter(timer => timer !== id)
            },
            3000 + index * 1000,
          )
        }
      })
    }
  },
  {
    deep: true,
  },
)
</script>

<style scoped lang="scss">
@import '@/styles/mixins.scss';
.snack-banner {
  display: flex;
  align-items: stretch;
  width: 100%;
  min-height: 72px;
  background: $black-90;
  border-radius: 16px;
  max-width: 900px;
  overflow: hidden;
  box-shadow: $box-shadow-lvl-1;
  color: white;
  gap: 16px;

  &-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 48px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
    max-width: 900px;
    width: 100%;
    min-height: 72px;

    &--empty {
      pointer-events: none;
    }

    @media screen and (max-width: 1023px) {
      max-width: calc(100% - 32px);
      top: 64px;
      bottom: initial;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  @media screen and (max-width: 1023px) {
    border-radius: 12px;
    min-height: 44px;
    gap: 0;
  }

  &__icon,
  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    flex-shrink: 0;

    @media screen and (max-width: 1023px) {
      width: 44px;

      .icon {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__text {
    padding: 8px;
    align-self: center;

    @media screen and (max-width: 1023px) {
      @include subhead4();
      padding: 12px;
    }
  }

  &__close {
    margin-left: auto;
    cursor: pointer;

    .nuxt-icon {
      display: block;
    }

    @media screen and (max-width: 1023px) {
      display: none;
    }
  }

  &--success {
    .snack-banner__icon {
      background: $success-100;

      :deep(svg) {
        path {
          stroke: $success-100;
        }
      }
    }
  }

  &--info {
    .snack-banner__icon {
      background: $alert-100;
    }
  }

  &--error {
    .snack-banner__icon {
      background: $error-100;
    }
  }
}

.reverseSlideY-enter-active,
.reverseSlideY-leave-active {
  transition: all 0.5s;
}

.reverseSlideY-enter-from,
.reverseSlideY-leave-to {
  opacity: 0;
  transform: translateY(32px);
}
</style>
